/** @format */

import { cn } from "@/lib/utils";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
const buttonVariants = cva("inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50", {
  variants: {
    variant: {
      raw: "",
      default: "bg-primary-accent--kheradmen text-primary-foreground--kheradmen" + " hover:bg-primary-accent--kheradmen/80",
      destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
      outline: "border border-input bg-background hover:bg-primary/20 hover:text-primary-foreground",
      secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
      ghost: "hover:bg-accent hover:text-accent-foreground",
      link: "text-primary underline-offset-4 hover:underline",
      ai: "hover:bg-white/10 bg-gradient-to-br from-blue-400 via-purple-500 to-pink-500 bg-opacity-40"
    },
    size: {
      default: "h-10 px-4 py-2",
      sm: "h-9 rounded-md px-3",
      lg: "h-11 rounded-md px-8 text-xl",
      "2xl": "h-12 rounded-lg px-12 text-lg lg:text-xl",
      "2xl-action": "h-14 rounded-lg px-8 text-xl lg:text-2xl",
      "3xl": "h-14 rounded-lg px-16 text-lg lg:text-2xl",
      "3xl-action": "h-16 rounded-lg px-8 text-xl lg:text-3xl",
      "4xl": "h-16 rounded-lg px-24 text-2xl lg:text-3xl",
      "4xl-small": "h-16 rounded-lg px-18 text-lg lg:text-xl",
      icon: "h-10 w-10",
      "hero-cta": "h-16 lg:h-12 rounded-full px-8 text-xl lg:text-base"
    }
  },
  defaultVariants: {
    variant: "default",
    size: "default"
  }
});
export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  className,
  variant,
  size,
  asChild = false,
  ...props
}, ref) => {
  const Comp = asChild ? Slot : "button";
  return <Comp className={cn(buttonVariants({
    variant,
    size,
    className
  }))} ref={ref} {...props} />;
});
Button.displayName = "Button";
export { Button, buttonVariants };