/** @format */

"use client";

import { BlogPost } from "@/components/blog/blog-posts";
import { Typography } from "@/components/ui/typography";
import { format } from "date-fns";
import Image from "next/image";
import NavigationLink from "../navlinks/NavigationLink";
interface BlogPostCardProps {
  post: BlogPost;
  idx: number;
}
const BlogPostCard = ({
  post
}: BlogPostCardProps) => <NavigationLink href={`/blog/${post.slug}`} className="group" data-sentry-element="NavigationLink" data-sentry-component="BlogPostCard" data-sentry-source-file="blog-post-card.tsx">
    <article className="flex flex-col p-4 mx-6 mb-6 overflow-hidden transition-all duration-300 rounded-lg shadow-sm bg-background hover:shadow-md hover:scale-105">
      <div className="aspect-[16/9] relative overflow-hidden">
        <Image src={post.image!} alt={post.title!} fill className="object-cover transition-transform duration-300 group-hover:scale-105" data-sentry-element="Image" data-sentry-source-file="blog-post-card.tsx" />
      </div>
      <div className="flex flex-col flex-grow p-4">
        <Typography variant="h3" size="xl" className="mb-2 font-semibold transition-colors group-hover:text-primary line-clamp-2" data-sentry-element="Typography" data-sentry-source-file="blog-post-card.tsx">
          {post.title}
        </Typography>
        <Typography variant="p" size="sm" className="mb-4 text-muted-foreground line-clamp-3" data-sentry-element="Typography" data-sentry-source-file="blog-post-card.tsx">
          {post.description}
        </Typography>
        <div className="flex items-center justify-between mt-auto text-xs text-muted-foreground">
          <span>{post.author}</span>
          <span>
            {format(post.date!, "MMM d, yyyy")} • {post.ttr} min read
          </span>
        </div>
      </div>
    </article>
  </NavigationLink>;
export default BlogPostCard;