/** @format */

import { NEXT_PUBLIC_BASE_URL, PORT } from "@/env";
import { LocalePrefix, Pathnames } from "next-intl/routing";
import { getRequestConfig } from "next-intl/server";

export const i18n = {
  locales: ["fa", "en"] as const,
  defaultLocale: "en",
} as const;

export type Locale = (typeof i18n.locales)[number];

export const getDirection = (locale: Locale): "rtl" | "ltr" =>
  locale === "fa" ? "rtl" : "ltr";

export const nextStaticGeneration = {
  dynamicParams: false,
  generateStaticParams: () => i18n.locales.map((locale) => ({ locale })),
};

export default getRequestConfig(async ({ locale }) => ({
  messages: (await import(`./messages/${locale}.json`)).default,
  now: new Date(),
  timeZone: locale === "en" ? "Europe/London" : "Asia/Tehran",
  formats: {
    dateTime: {
      short: {
        day: "numeric",
        month: "short",
        year: "numeric",
      },
    },
    number: {
      precise: {
        maximumFractionDigits: 5,
      },
    },
    list: {
      enumeration: {
        style: "long",
        type: "conjunction",
      },
    },
  },
}));

export const locales = i18n.locales;
export const defaultLocale = i18n.defaultLocale;
export const localePrefix: LocalePrefix<typeof locales> = "always";

export const port = PORT || 3000;
export const host = NEXT_PUBLIC_BASE_URL
  ? NEXT_PUBLIC_BASE_URL
  : `http://127.0.0.1:${port}`;

export const pathnames: Pathnames<typeof locales> = {
  "/": "/",
  "/careers": {
    en: "/careers",
    fa: "/فرصت-شغلی",
  },
  "/blog": {
    en: "/blog",
    fa: "/بلاگ",
  },
  "/articles": {
    en: "/research",
    fa: "/مقالات",
  },
  "/products": {
    en: "/products",
    fa: "/محصولات",
  },
  "/about-us": {
    en: "/about-us",
    fa: "/درباره-ما",
  },
  "/contact-us": {
    en: "/contact",
    fa: "/تماس",
  },
  "/product/hami": {
    en: "/product/hami",
    fa: "/محصول/حامی",
  },
  "/product/carestyle": {
    en: "/product/carestyle",
    fa: "/محصول/کراستایل",
  },
  "/product/medguard": {
    en: "/product/medguard",
    fa: "/محصول/مدگارد",
  },
  "/product/helina": {
    en: "/product/helina",
    fa: "/محصول/هلینا",
  },
  "/product/robovakil": {
    en: "/product/robovakil",
    fa: "/محصول/ربووکیل",
  },
  "/product/autonoma": {
    en: "/product/autonoma",
    fa: "/محصول/اتونوما",
  },
};

type PN = typeof pathnames;
export type {
  PN as Pathnames, // Pathnames
};

export type Pages = "careers" | "blog" | "products" | "about" | "contact" | "articles";
