"use client";

import BlogPostCard from "@/components/blog/blog-post-card";
import { blogsPosts } from "@/components/blog/blog-posts";
import { Typography } from "@/components/ui/typography";
import { useLocale, useTranslations } from "next-intl";
import React, { useMemo } from "react";
const BlogSection: React.FC = () => {
  const t = useTranslations("Blog");
  const locale = useLocale();
  const filteredPosts = useMemo(() => blogsPosts.slice(0, 4).filter(b => b.language === locale), [locale]);
  if (filteredPosts.length === 0) return null;
  return <section className="min-h-screen bg-card/50" data-sentry-component="BlogSection" data-sentry-source-file="blog-section.tsx">
      <div className="container py-20">
        <Typography variant="h1" size="title" data-sentry-element="Typography" data-sentry-source-file="blog-section.tsx">
          {t("title")}
        </Typography>
        {filteredPosts.length === 0 && <Typography variant="p" className="text-center">
            {t("no-post")}
          </Typography>}
        <section className="w-full gap-4 columns-1 md:columns-2">
          <div className="min-h-64" />
          {filteredPosts.map((post, idx) => <BlogPostCard post={post} idx={idx} key={idx} />)}
          <div className="min-h-64" />
        </section>
      </div>
    </section>;
};
export default BlogSection;