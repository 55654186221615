"use client";

import { cn } from "@/lib/utils";
import { cva, type VariantProps } from "class-variance-authority";
import { createElement, forwardRef, HTMLAttributes, JSX } from "react";
const typographyVariants = cva("", {
  variants: {
    variant: {
      default: "text-base font-normal",
      h1: "text-4xl font-bold tracking-tight",
      h2: "text-3xl font-semibold tracking-tight",
      h3: "text-2xl tracking-tight",
      h4: "text-xl",
      h5: "text-lg",
      h6: "text-base",
      p: "text-base"
    },
    size: {
      default: "",
      title: "text-2xl lg:text-3xl",
      "title-xl": "text-3xl lg:text-5xl font-semibold",
      "7xl-title": "text-4xl lg:text-7xl",
      "6xl-title": "text-4xl lg:text-6xl rtl:text-5xl",
      base: "text-base",
      xs: "text-xs",
      sm: "text-sm",
      md: "text-md",
      lg: "text-lg",
      xl: "text-xl",
      "2xl": "text-2xl",
      "3xl": "text-3xl",
      "4xl": "text-4xl",
      "5xl": "text-5xl",
      "6xl": "text-6xl",
      "7xl": "text-7xl",
      "8xl": "text-8xl",
      "9xl": "text-9xl"
    }
  },
  defaultVariants: {
    size: "default",
    variant: "default"
  }
});
type TypographyElement = HTMLHeadingElement | HTMLParagraphElement | HTMLDivElement;
export interface TypographyProps extends HTMLAttributes<TypographyElement>, VariantProps<typeof typographyVariants> {
  component?: keyof JSX.IntrinsicElements;
}
export const Typography = forwardRef<TypographyElement, TypographyProps>(({
  className,
  variant,
  size,
  component = "div",
  ...props
}, ref) => {
  const Comp = (variant && ["h1", "h2", "h3", "h4", "h5", "h6", "p"].includes(variant) ? variant : component) as keyof JSX.IntrinsicElements;
  return createElement(Comp, {
    ref,
    className: cn(typographyVariants({
      variant,
      size,
      className
    })),
    ...props
  });
});
Typography.displayName = "Typography";
export { typographyVariants };