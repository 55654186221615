/** @format */

"use client";

import { cn } from "@/lib/utils";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslations } from "next-intl";
import Image from "next/image";
import React, { useEffect, useRef } from "react";
import { categories, type Category, type Product, productsData } from "./products.data";
import { Button } from "@/components/ui/button";
import NavigationLink from "@/components/navlinks/NavigationLink";
const ProductsSection: React.FC = () => {
  const [activeCategory, setActiveCategory] = React.useState<Category>(categories[0]);
  const [activeProduct, setActiveProduct] = React.useState<Product>(activeCategory.products[0]);
  useEffect(() => {
    setActiveProduct(activeCategory.products[0]);
  }, [activeCategory]);
  const sectionRef = useRef<HTMLDivElement>(null);
  const productRefs = useRef<(HTMLDivElement | null)[]>([]);
  const lastScrollTop = useRef(0);
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5
    };
    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const index = productRefs.current.findIndex(ref => ref === entry.target);
          if (index !== -1) {
            let currentIndex = 0;
            for (const category of categories) {
              if (currentIndex + category.products.length > index) {
                setActiveCategory(category);

                // Get the index of the current active category
                const currentActiveCategoryIndex = categories.findIndex(cat => cat === activeCategory);

                // Get the index of the category that's going to be activated
                const newActiveCategoryIndex = categories.findIndex(cat => cat === category);
                if (currentActiveCategoryIndex > newActiveCategoryIndex) {
                  // If current active category is after the new active category,
                  // set the last product of the new category as active
                  setActiveProduct(category.products[category.products.length - 1]);
                } else if (currentActiveCategoryIndex < newActiveCategoryIndex) {
                  // If current active category is before the new active category,
                  // set the first product of the new category as active
                  setActiveProduct(category.products[0]);
                } else {
                  // If it's the same category or it's the initial setup,
                  // set the product at the calculated index
                  setActiveProduct(category.products[index - currentIndex]);
                }
                break;
              }
              currentIndex += category.products.length;
            }
          }
        }
      });
    };
    const observer = new IntersectionObserver(handleIntersect, options);
    productRefs.current.forEach(ref => {
      if (ref) observer.observe(ref);
    });
    return () => observer.disconnect();
  }, [activeCategory]);
  useEffect(() => {
    const handleScroll = () => {
      const st = window.scrollY || document.documentElement.scrollTop;
      lastScrollTop.current = st <= 0 ? 0 : st;
      const section = sectionRef.current;
      const products = document.getElementById("products");
      if (!section || !products) return;
      const sectionRect = section.getBoundingClientRect();
      const productsRect = products.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      if (sectionRect.top <= 0 && sectionRect.bottom >= viewportHeight) {
        products.style.position = "fixed";
        products.style.top = "50%";
        products.style.transform = "translateY(-50%)";
      } else if (sectionRect.bottom < viewportHeight) {
        products.style.position = "absolute";
        products.style.top = `${sectionRect.height - productsRect.height}px`;
        products.style.bottom = "auto";
        products.style.transform = "translateY(0)";
      } else {
        products.style.position = "absolute";
        products.style.top = "0";
        products.style.bottom = "auto";
        products.style.transform = "translateY(0)";
      }
    };
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const scrollToProduct = (product: Product) => {
    const index = productsData.findIndex(p => p.name === product.name);
    if (index !== -1 && productRefs.current[index]) {
      productRefs.current[index]?.scrollIntoView({
        behavior: "smooth"
      });
    }
  };
  const handleCategoryClick = (category: Category) => {
    scrollToProduct(category.products[0]); // let the observer handle the rest
  };
  const handleProductClick = (product: Product) => {
    scrollToProduct(product); // let the observer handle the rest
  };
  const t = useTranslations(`Product`);
  return <section ref={sectionRef} className="relative" data-sentry-component="ProductsSection" data-sentry-source-file="products.tsx">
      <div id="products" className="flex flex-col items-center justify-center w-full h-screen">
        <div className="container flex flex-col w-full py-8 lg:flex-row">
          <div className="relative flex-row lg:flex-col product-side min-h-fit lg:min-h-144 max-w-auto md:max-w-36">
            {categories.map((category, index) => <motion.div key={category.name} onClick={() => handleCategoryClick(category)} className={cn("category-button p-4 bg-primary-accent--kheradmen/5 dark:bg-white/5 flex-1", "cursor-pointer transition-all duration-300 ease-in-out", activeCategory === category ? ["active bg-primary-accent--kheradmen/5 dark:bg-white/10", "pr-4 lg:pr-8 rtl:pr-4 rtl:lg:pr-4 rtl:pl-4 rtl:lg:pl-8 pb-8 lg:pb-4",
          // Mobile (default)
          "rounded-tl rounded-tr",
          // Desktop LTR
          "lg:rounded-l lg:rounded-tr-none lg:rounded-br-none",
          // Desktop RTL
          "rtl:lg:rounded-r rtl:lg:rounded-tl-none rtl:lg:rounded-bl-none"] : ["rounded", "mr-0 lg:mr-4 rtl:mr-0 rtl:ml-0 rtl:lg:ml-4 mb-4 lg:mb-0"])} style={{
            zIndex: activeCategory === category ? categories.length : categories.length - index
          }} whileTap={{
            scale: 0.95
          }} layout>
                <motion.div className="flex flex-col items-center justify-center w-full h-full gap-2 text-lg font-bold" layout>
                  <motion.span className="flex items-center justify-center bg-black rounded size-16" layout>
                    <Image src={`/img/icons/${category.name}.svg`} width={40} height={40} alt={t(`Category.${category.name}.name`)} />
                  </motion.span>
                  <motion.span layout className="text-sm lg:text-base">
                    {t(`Category.${category.name}.name`)}
                  </motion.span>
                </motion.div>
              </motion.div>)}
          </div>

          <motion.div className={cn("flex-grow w-full bg-primary-accent--kheradmen/10 dark:bg-white/10 overflow-hidden", activeCategory === categories[0] && ["rounded-tr rounded-b", "rtl:rounded-tr-none rtl:rounded-tl"], activeCategory !== categories[0] && activeCategory !== categories[categories.length - 1] && "rounded", activeCategory === categories[categories.length - 1] && ["rounded-tl rounded-bl rounded-br md:rounded-t md:rounded-r md:rounded-l-none rtl:md:rounded-r-none rtl:rounded-tr rtl:rounded-bl rtl:rounded-br rtl:md:rounded-t rtl:md:rounded-l"])} layout data-sentry-element="unknown" data-sentry-source-file="products.tsx">
            <section className="flex flex-col w-full h-full">
              {/* Product Container */}
              <AnimatePresence mode="wait" data-sentry-element="AnimatePresence" data-sentry-source-file="products.tsx">
                <motion.div key={activeProduct.name} initial={{
                opacity: 0,
                y: 20
              }} animate={{
                opacity: 1,
                y: 0
              }} exit={{
                opacity: 0,
                y: -20
              }} transition={{
                duration: 0.3
              }} className="flex flex-col items-center flex-grow h-full gap-4 p-4 md:flex-row-reverse rtl:md:flex-row-reverse md:gap-8 md:p-8" layout data-sentry-element="unknown" data-sentry-source-file="products.tsx">
                  <motion.div className="w-full md:w-1/2 h-64 md:h-108 scale-x-[-1] rtl:scale-x-1 relative mix-blend-overlay order-2 md:order-none hidden md:block" layout data-sentry-element="unknown" data-sentry-source-file="products.tsx">
                    <Image className="object-contain" src={activeProduct.image} fill alt={t(`${activeProduct.name}.title`)} data-sentry-element="Image" data-sentry-source-file="products.tsx" />
                  </motion.div>
                  <motion.div className="flex flex-col items-center justify-between w-full h-full gap-4 md:items-start md:w-1/2" layout data-sentry-element="unknown" data-sentry-source-file="products.tsx">
                    <div className="order-1 md:order-none">
                      <motion.p className="mb-2 text-sm text-center uppercase md:text-lg whitespace-nowrap md:text-start" layout data-sentry-element="unknown" data-sentry-source-file="products.tsx">
                        {t(`${activeProduct.name}.subtitle`)}
                      </motion.p>
                      <motion.h1 className="mb-4 text-2xl font-bold text-center md:text-5xl md:text-start" layout data-sentry-element="unknown" data-sentry-source-file="products.tsx">
                        {t(`${activeProduct.name}.title`)}
                      </motion.h1>
                    </div>

                    <div className="flex items-center justify-center order-2 md:hidden">
                      <motion.div className="size-52 scale-x-[-1] rtl:scale-x-1 relative mix-blend-overlay " layout data-sentry-element="unknown" data-sentry-source-file="products.tsx">
                        <Image className="object-contain" src={activeProduct.image} fill alt={t(`${activeProduct.name}.title`)} data-sentry-element="Image" data-sentry-source-file="products.tsx" />
                      </motion.div>
                    </div>

                    <motion.p className="order-3 mb-4 text-sm md:mb-6 md:text-base md:order-none" layout data-sentry-element="unknown" data-sentry-source-file="products.tsx">
                      {t(`${activeProduct.name}.description`)}
                    </motion.p>

                    <div className="flex flex-col items-start justify-center order-4 h-20 md:h-32 md:order-none">
                      <Button asChild className="px-4 py-2 text-sm text-white transition-colors duration-300 rounded-full md:px-6 md:text-base bg-primary-accent--kheradmen hover:bg-primary-accent--kheradmen/80" data-sentry-element="Button" data-sentry-source-file="products.tsx">
                        <NavigationLink href={`/product/${activeProduct.name.toLowerCase()}`} data-sentry-element="NavigationLink" data-sentry-source-file="products.tsx">
                          <motion.button whileHover={{
                          scale: 1.05
                        }} whileTap={{
                          scale: 0.95
                        }} layout data-sentry-element="unknown" data-sentry-source-file="products.tsx">
                            {t(`${activeProduct.name}.cta`)}
                          </motion.button>
                        </NavigationLink>
                      </Button>
                    </div>
                  </motion.div>
                </motion.div>
              </AnimatePresence>

              {/* Bottom Bar (Product List) */}
              <motion.div className="flex w-full overflow-x-scroll bg-primary-accent--kheradmen/5 dark:bg-white/10" style={{
              borderRadius: "inherit"
            }} layout data-sentry-element="unknown" data-sentry-source-file="products.tsx">
                {activeCategory.products.map(product => <motion.div key={product.name} onClick={() => handleProductClick(product)} style={{
                borderRadius: "inherit"
              }} className={cn("flex justify-center items-center w-full border border-black/5 py-3", "cursor-pointer transition-all duration-300", "hover:bg-primary-accent--kheradmen/10", "min-w-fit flex-1 flex-grow px-4", activeProduct === product && "bg-primary-accent--kheradmen/10 dark:bg-white/15 hover:bg-primary-accent--kheradmen/15 font-bold")} whileTap={{
                scale: 0.95
              }} layout>
                    <motion.h2 className="text-lg" layout>
                      {t(`${product.name}.title`)}
                    </motion.h2>
                  </motion.div>)}
              </motion.div>
            </section>
          </motion.div>
        </div>
      </div>

      {/*
        this is what makes the scroll magic possible, don't remove thinking it's useless
        */}
      {productsData.map((_, index) => <div key={index}
    // @ts-expect-error - Ref is never null, it will be filled in the first render
    ref={el => productRefs.current[index] = el} className="h-dvh md:h-screen" />)}
    </section>;
};
export default ProductsSection;