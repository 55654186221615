/** @format */

"use client";

import { cn } from "@/lib/utils";
import { ArrowLeft } from "lucide-react";
import { useTranslations } from "next-intl";
import React from "react";
import NavigationLink from "../navlinks/NavigationLink";
import { Button } from "../ui/button";
interface CareerBannerProps {
  className?: string;
}
const CareerBanner: React.FC<CareerBannerProps> = ({
  className = ""
}) => {
  const t = useTranslations("CareerBanner");
  return <div className={cn("container bg-primary-accent--kheradmen/10 p-8 md:p-12 rounded", className)} data-sentry-component="CareerBanner" data-sentry-source-file="CareerBanner.tsx">
      <div className="flex flex-col items-center justify-between space-y-6 md:flex-row md:space-y-0">
        <div className="space-y-8 text-center md:text-left rtl:md:text-right">
          <h2 className="text-3xl font-bold">{t("title")}</h2>
          <p className="text-lg">{t("description")}</p>
        </div>
        <Button asChild size="4xl-small" className={cn("inline-flex items-center px-6 py-3 text-lg font-medium rounded-full", "hover:bg-opacity-90 transition duration-300", "transform hover:translate-x-1 rtl:hover:-translate-x-1")} data-sentry-element="Button" data-sentry-source-file="CareerBanner.tsx">
          <NavigationLink href="/careers" data-sentry-element="NavigationLink" data-sentry-source-file="CareerBanner.tsx">
            {t("applyNow")}
            <ArrowLeft className={cn("size-6", "ml-2 rtl:mr-2", "scale-x-[-1] rtl:scale-x-1")} data-sentry-element="ArrowLeft" data-sentry-source-file="CareerBanner.tsx" />
          </NavigationLink>
        </Button>
      </div>
    </div>;
};
export default CareerBanner;