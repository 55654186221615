/** @format */

const productNames = [
  "Hami",
  "Medguard",
  "Carestyle",
  "Helina",
  "Autonoma",
  "Robovakil",
] as const;

const categoryNames = ["Medical", "Technology", "Legal"] as const;

type ProductName = (typeof productNames)[number];
type CategoryName = (typeof categoryNames)[number];

export type Product = {
  name: ProductName;
  category: CategoryName;
  image: `/img/products/${Lowercase<ProductName>}.png`;
};

export type Category = {
  name: CategoryName;
  products: Product[];
};

const productCategoryMap: Record<ProductName, CategoryName> = {
  Hami: "Medical",
  Medguard: "Medical",
  Carestyle: "Medical",
  Helina: "Medical",
  Robovakil: "Legal",
  Autonoma: "Technology",
};

export const productsData: Product[] = productNames.map((name: ProductName) => {
  return {
    name: name,
    category: productCategoryMap[name],
    image: `/img/products/${name.toLowerCase() as Lowercase<ProductName>}.png`,
  };
});

export const categories: Category[] = categoryNames.map(
  (name: CategoryName) => {
    return {
      name: name,
      products: productsData.filter((product) => product.category === name),
    };
  }
);

export const products = productsData;
