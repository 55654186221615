export type BlogPost = {
  title?: string;
  description?: string;
  image?: string;
  date?: string | Date;
  views?: number;
  author?: string;
  ttr?: number;
  language?: "en" | "fa";
  slug?: string;
  tags?: string[];
};
export const blogsPosts: BlogPost[] = [{
  slug: `الکتروانسفالوگرافی-eeg-پنجره‌ای-به-دنیای-پیچیده-مغز-انسان`,
  title: "الکتروانسفالوگرافی (EEG): پنجره‌ای به دنیای پیچیده مغز انسان",
  description: "الکتروانسفالوگرافی (EEG) یک روش غیرتهاجمی برای ثبت فعالیت الکتریکی مغز است. این مقاله به بررسی چیستی EEG، نحوه کارکرد آن، کاربردهای مهم در پزشکی و تحقیقات، چالش‌ها و راه‌حل‌های موجود، و چشم‌انداز آینده این فناوری می‌پردازد.",
  image: "/img/blog/eeg.svg",
  date: new Date("2024-07-27").toISOString(),
  views: 100,
  ttr: 12,
  author: "Mahdieh Ebrahimi",
  language: "fa"
},
// {
//   slug: `نوروساینس-مدرن-پیوند-علم-مغز-هوش-مصنوعی-و-روانپزشکی`,
//   title: "نوروساینس مدرن: پیوند علم مغز، هوش مصنوعی و روانپزشکی",
//   description: "توضیحات: کاوشی عمیق در دنیای نوروساینس، تأثیر هوش مصنوعی بر پیشرفت‌های اخیر، و ارتباط آن
// با روانپزشکی مدرن. از تاریخچه تا چشم‌اندازهای آینده این علم پیشرو را بررسی می‌کنیم.", image:
// "/img/blog/ns.svg", date: "2024 June 20", views: 325, ttr: 8, author: "Mahdieh Ebrahimi",  language:
// "fa" },
{
  slug: `یادگیری-تقویتی-انقلابی-در-هوش-مصنوعی-و-کاربردهای-آن`,
  title: "یادگیری تقویتی انقلابی در هوش مصنوعی و کاربردهای آن",
  description: "کاوشی جامع در دنیای یادگیری تقویتی، از مفاهیم پایه تا الگوریتم‌های پیشرفته مانند SAC. این مقاله به بررسی" + " کاربردها، تفاوت‌ها و آینده این تکنولوژی انقلابی در هوش مصنوعی می‌پردازد.",
  image: "/img/blog/ns.svg",
  date: new Date("2024-02-27").toISOString(),
  views: 4587,
  ttr: 18,
  author: "Soheil Dehghani",
  language: "fa"
}];