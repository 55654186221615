/** @format */

"use client";

import NavigationLink from "@/components/navlinks/NavigationLink";
import { Button } from "@/components/ui/button";
import { Typography } from "@/components/ui/typography";
import { motion } from "framer-motion";
import { useTranslations } from "next-intl";
import React from "react";
const AboutUsSection: React.FC = () => {
  const t = useTranslations("About");
  const fadeInVariants = {
    initial: {
      opacity: 0,
      y: 20
    },
    animate: {
      opacity: 1,
      y: 0
    }
  };
  return <motion.section id="about" className="container py-16 mx-auto" initial="initial" animate="animate" variants={{
    animate: {
      transition: {
        staggerChildren: 0.1
      }
    }
  }} data-sentry-element="unknown" data-sentry-component="AboutUsSection" data-sentry-source-file="AboutKheradmen.tsx">
      <motion.div className="mx-auto space-y-12" variants={fadeInVariants} data-sentry-element="unknown" data-sentry-source-file="AboutKheradmen.tsx">
        <Typography variant="h1" size="title" className="font-bold text-foreground-text--kheradmen" data-sentry-element="Typography" data-sentry-source-file="AboutKheradmen.tsx">
          {t("title")}
        </Typography>

        <Typography variant="p" size="lg" className="text-foreground-text--kheradmen" data-sentry-element="Typography" data-sentry-source-file="AboutKheradmen.tsx">
          {t("description")}
        </Typography>

        <div className="flex justify-end w-full">
          <Button className="rounded-full" size="2xl-action" asChild data-sentry-element="Button" data-sentry-source-file="AboutKheradmen.tsx">
            <NavigationLink href="/about-us" data-sentry-element="NavigationLink" data-sentry-source-file="AboutKheradmen.tsx">{t("button")}</NavigationLink>
          </Button>
        </div>
      </motion.div>
    </motion.section>;
};
export default AboutUsSection;