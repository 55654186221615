/** @format */
"use client";

import React from "react";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { motion } from "framer-motion";
import { IoIosArrowForward } from "react-icons/io";
import { Button } from "@/components/ui/button";
import { Typography } from "@/components/ui/typography";
import { cn } from "@/lib/utils";
const HeroSection: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => {
  const t = useTranslations("Hero");
  const scrollToContent = (e: React.MouseEvent) => {
    e.preventDefault();
    window.scrollTo({
      top: 2.01 * window.innerHeight,
      behavior: "smooth"
    });
  };
  return <section className={cn("container flex min-h-screen flex-col items-center justify-center gap-12 py-4 lg:flex-row lg:gap-16 lg:py-24", className)} {...props} data-sentry-component="HeroSection" data-sentry-source-file="KheradmenHero.tsx">
      <motion.div initial={{
      opacity: 0,
      scale: 0.8,
      rotate: -5
    }} animate={{
      opacity: 1,
      scale: 1,
      rotate: 0
    }} transition={{
      duration: 0.8,
      ease: "easeOut"
    }} className="flex items-center justify-center order-1 w-full mb-8 lg:order-2 lg:mb-0 lg:justify-start" data-sentry-element="unknown" data-sentry-source-file="KheradmenHero.tsx">
        <div className="relative flex justify-center w-full px-4 sm:max-w-xs sm:px-0 lg:max-w-md xl:max-w-lg">
          <motion.div initial={{
          opacity: 0,
          scale: 0.8,
          rotate: -5
        }} animate={{
          opacity: 1,
          scale: 1,
          rotate: 0
        }} transition={{
          duration: 0.8,
          ease: "easeOut"
        }} className="relative w-full" style={{
          aspectRatio: "1 / 1"
        }} data-sentry-element="unknown" data-sentry-source-file="KheradmenHero.tsx">
            <Image
          // src="/assets/kherad-tree-v.png"
          src="/assets/kheradmen-animated-logo-dark.svg" alt="Kheradmen Hero Image" fill priority objectFit="contain" className="hidden dark:block" data-sentry-element="Image" data-sentry-source-file="KheradmenHero.tsx" />
            <Image
          // src="/assets/kherad-tree-v.png"
          src="/assets/kheradmen-animated-logo.svg" alt="Kheradmen Hero Image" fill priority objectFit="contain" className="block dark:hidden" data-sentry-element="Image" data-sentry-source-file="KheradmenHero.tsx" />
          </motion.div>
        </div>
      </motion.div>

      <motion.div initial={{
      opacity: 0,
      y: 20
    }} animate={{
      opacity: 1,
      y: 0
    }} transition={{
      duration: 0.6
    }} className="z-10 order-2 w-full max-w-2xl space-y-12 lg:order-1 lg:space-y-16" data-sentry-element="unknown" data-sentry-source-file="KheradmenHero.tsx">
        <motion.div className="space-y-6 leading-loose text-foreground rtl:space-y-8 lg:space-y-10 lg:rtl:space-y-14" initial={{
        opacity: 0,
        y: -20
      }} animate={{
        opacity: 1,
        y: 0
      }} transition={{
        delay: 0.2,
        duration: 0.5
      }} data-sentry-element="unknown" data-sentry-source-file="KheradmenHero.tsx">
          <Typography variant="h1" size="6xl-title" className={cn("text-start font-extrabold text-foreground-text--kheradmen text-5xl [text-shadow:_0_1px_2px_rgba(0,0,0,0.1)] lg:text-6xl", "ltr:leading-tight rtl:whitespace-pre rtl:leading-[1.3] rtl:pt-4 lg:rtl:pt-12")} data-sentry-element="Typography" data-sentry-source-file="KheradmenHero.tsx">
            {t("title")}
          </Typography>
        </motion.div>

        <motion.div initial={{
        opacity: 0,
        y: 20
      }} animate={{
        opacity: 1,
        y: 0
      }} transition={{
        duration: 0.5
      }} className="w-full" data-sentry-element="unknown" data-sentry-source-file="KheradmenHero.tsx">
          <Button onClick={scrollToContent} variant="raw" size="hero-cta" className={cn("hero-button rounded-full", "max-w-fit", "px-4 py-6", "flex items-center justify-between gap-6", "tracking-wide rtl:tracking-tight min-w-full lg:min-w-fit")} data-sentry-element="Button" data-sentry-source-file="KheradmenHero.tsx">
            {t("cta")}
            <motion.div whileHover={{
            x: 5
          }} transition={{
            type: "spring",
            stiffness: 400,
            damping: 10
          }} data-sentry-element="unknown" data-sentry-source-file="KheradmenHero.tsx">
              <IoIosArrowForward className={cn("size-5 transition-transform duration-200 ease-in-out", "rtl:rotate-180")} data-sentry-element="IoIosArrowForward" data-sentry-source-file="KheradmenHero.tsx" />
            </motion.div>
          </Button>
        </motion.div>
      </motion.div>
    </section>;
};
export default HeroSection;