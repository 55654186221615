/** @format */

// For server-side
export const NODE_ENV = process.env.NODE_ENV || "production";
export const PORT = process.env.PORT || "3000";

// For both server-side and client-side
export const NEXT_PUBLIC_BASE_URL =
  process.env.NEXT_PUBLIC_BASE_URL || "https://kheradmen.com";

// Function to determine the backend API URL
const getBackendApiUrl = () => {
  if (process.env.NEXT_PUBLIC_BACKEND_API) {
    return process.env.NEXT_PUBLIC_BACKEND_API;
  }

  if (process.env.NODE_ENV === "development") {
    return "https://stageapi.kheradmen.com";
  }

  if (typeof window !== "undefined") {
    return window.location.hostname === "stage.kheradmen.com"
      ? "https://stageapi.kheradmen.com"
      : "https://api.kheradmen.com";
  }

  return "https://api.kheradmen.com";
};

export const NEXT_PUBLIC_BACKEND_API = getBackendApiUrl();

export const DEFAULT_PROJECT_ID =
  process.env.NEXT_PUBLIC_DEFAULT_PROJECT_ID || "p5xqpV1M8W";
